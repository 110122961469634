import _ from 'lodash';
import { render, unmountComponentAtNode } from 'react-dom';
import React from 'react';
import { jqx } from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';
import moment from 'moment';
import ISOConfirmDialog from '../../components/controls/ISOConfirmDialog';
import Loader from '../../components/loaderable/Loader';
import ISOAlert from '../../components/controls/ISOAlert';
import SysData from './SysData';
import base64 from 'base-64';
import axios from 'axios';

export { SysData };

const dataGridApi = {
  selectionMode: {
    multipleRows: 'multipleRows',
    singleRow: 'singleRow',
    custom: 'custom',
    none: 'none',
  },
  align: {
    center: 'center',
    left: 'left',
    right: 'right',
  },
  addColumnGroup: ({ name, label, align = 'center' }) => {
    let group = {};
    group.name = name;
    group.text = label;
    group.align = align;
    return group;
  },
  addColumn: ({
    dataField,
    label,
    width = 50,
    align = 'center',
    columnGroup,
    dataType = 'string',
    cellsFormat,
    allowEdit = false,
    visible = true,
    cellsrenderer,
  }) => {
    let column = {};
    column.align = 'center';
    column.datafield = dataField;
    column.text = label;
    column.width = width;
    column.dataType = dataType;
    column.cellsalign = align;
    column.editable = allowEdit;

    if (cellsrenderer) {
      column.cellsrenderer = cellsrenderer;
    }

    if (columnGroup) {
      column.columnGroup = columnGroup;
    }

    if (cellsFormat) {
      column.cellsformat = cellsFormat;
    }

    column.hidden = !visible;
    return column;
  },
};

const treeGridApi = {
  rowState: {
    add: 'add',
    new: 'new',
  },
  selectionMode: {
    multipleRows: 'multipleRows',
    singleRow: 'singleRow',
    custom: 'custom',
    none: 'none',
  },
  addColumnGroup: ({ name, label, align }) => {
    let group = {};
    group.name = name;
    group.text = label;
    group.align = align;
    return group;
  },
  addColumn: ({
    dataField,
    label,
    width = 50,
    align = 'center',
    groupName,
    dataType = 'string',
    cellsFormat,
    hidden = false,
  }) => {
    let column = {};
    column.align = 'center';
    column.dataField = dataField;
    column.text = label;
    column.width = width;
    column.dataType = dataType;
    column.cellsAlign = align;

    if (groupName) {
      column.columnGroup = groupName;
    }

    if (cellsFormat) {
      column.cellsFormat = cellsFormat;
    }

    column.hidden = hidden;
    return column;
  },
};

const mergeGridApi = {
  rowState: {
    add: 'add',
    new: 'new',
    total: 'total',
    subTotal: 'subTotal',
  },
  editorType: {
    tags: 'tags',
    input: 'input',
    dropDownList: 'dropDownList',
    checkBox: 'checkBox',
    numberInput: 'numberInput',
    autoComplete: 'autoComplete',
    comboBox: 'comboBox',
    image: 'image',
    multiInput: 'multiInput',
    multiComboInput: 'multiComboInput',
    checkInput: 'checkInput',
    slider: 'slider',
    dateTimePicker: 'dateTimePicker',
    timeInput: 'timeInput',
    dateInput: 'dateInput',
    dateRangeInput: 'dateRangeInput',
    maskedTextBox: 'maskedTextBox',
    textArea: 'textArea',
  },
  selectionMode: {
    SingleSelection: 'one',
    MultipleSelection: 'many',
    Extended: 'extended',
  },
  dataType: {
    string: 'string',
    number: 'number',
  },
  addColumnGroup: ({ label, align, name }) => {
    const group = {};
    group.label = label;
    group.align = align;
    group.name = name;
    return group;
  },
  addColumn: ({
    label,
    dataField,
    width = 50,
    allowEdit = false,
    hAlign = 'center',
    vAlign = 'middle',
    visible = true,
    columnGroup,
    template,
    editor,
    dataType,
    formatFunction,
    allowResize = true,
  }) => {
    const column = {};
    column.align = 'center';
    column.label = label;
    column.dataField = dataField;
    column.width = width;
    column.allowEdit = allowEdit;
    column.cellsAlign = hAlign;
    column.cellsVerticalAlign = vAlign;
    column.verticalAlign = 'middle';
    column.allowResize = allowResize;
    column.visible = visible;

    if (template) {
      column.template = template;
    }

    if (editor) {
      column.editor = editor;
    }

    if (columnGroup) {
      column.columnGroup = columnGroup;
    }

    if (formatFunction) {
      column.formatFunction = formatFunction;
    }

    if (dataType) {
      column.dataType = dataType;
    }

    return column;
  },
  addSubTotalColumns: ({ summaryType, groupField, dataField, labelField, title, labelColSpan = 1 }) => {
    const column = {};
    column.summaryType = summaryType;
    column.groupField = groupField;
    column.dataField = dataField;
    column.labelField = labelField;
    column.title = title;
    column.labelColSpan = labelColSpan;
    return column;
  },
};

const tableGridApi = {
  RowState: {
    Detached: 'Detached',
    Unchanged: 'Unchanged',
    Added: 'Added',
    Deleted: 'Deleted',
    Modified: 'Modified',
    Total: 'Total',
    SubTotal: 'SubTotal',
  },
  SelectionMode: {
    Row: 'Row',
    Cell: 'Cell',
  },
  addColumnGroup: ({ label, align, name }) => {
    const group = {};
    group.label = label;
    group.align = align;
    group.name = name;
    return group;
  },
  addColumn: ({
    label,
    dataField,
    width = 50,
    allowEdit = false,
    hAlign = 'center',
    vAlign = 'middle',
    visible = true,
    columnGroup,
    template,
    editor,
    dataType,
    formatFunction,
    allowResize = true,
    align,
    columnType
  }) => {
    const column = {};
    column.align = align;
    column.label = label;
    column.dataField = dataField;
    column.width = width;
    column.allowEdit = allowEdit;
    column.cellsAlign = hAlign;
    column.cellsVerticalAlign = vAlign;
    column.verticalAlign = 'middle';
    column.allowResize = allowResize;
    column.visible = visible;
    // ysh 22.11.18
    column.columnType = columnType;

    if (template) {
      column.template = template;
    }

    if (editor) {
      column.editor = editor;
    }

    if (columnGroup) {
      column.columnGroup = columnGroup;
    }

    if (formatFunction) {
      column.formatFunction = formatFunction;
    }

    if (dataType) {
      column.dataType = dataType;
    }

    return column;
  },
};

const kanbanApi = {
  addColumn: ({ label, dataField, maxItems, iconClassName, collapsible = false }) => {
    const column = {};

    column.label = label;
    column.dataField = dataField;

    if (maxItems !== undefined) {
      column.maxItems = maxItems;
    }

    if (iconClassName !== undefined) {
      column.iconClassName = iconClassName;
    }

    column.collapsible = collapsible;

    return column;
  },
};

const uploadFile = async (file, uploadFileCallback) => {
  const API_URL = process.env.REACT_APP_FILESERVICE;
  const req = new FormData();
  if (file) {
    req.append('fileStream', file);
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    await axios
      .post(`${API_URL}/Upload`, req, config)
      .then((res) => {
        if (res) {
          const ret = jsonCheck(res.data);

          if (!ret.ErrorMessage) {
            const fileUploadResult = JSON.parse(ret.JsonData);

            if (uploadFileCallback) {
              uploadFileCallback(fileUploadResult);
            }
          } else {
            e3.modal.alert(alertType.Error, ret.ErrorMessage);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

/**
 * true // console enable
 * false // console disable
 */
export const isDebug = () => true;
/**
 * Combine paths
 *
 * @param {string} parent
 * @param {string} child
 * @returns {string}
 */
export const combinePaths = (parent, child) => `${parent.replace(/\/$/, '')}/${child.replace(/^\//, '')}`;

/**
 * Recursively build paths for each navigation item
 *
 * @param routes
 * @param {string} parentPath
 * @returns {*}
 */
export const buildPaths = (routes, parentPath = '') =>
  routes.map((route) => {
    const path = combinePaths(parentPath, route.path);
    return {
      ...route,
      path,
      ...(route.routes && { routes: buildPaths(route.routes, path) }),
    };
  });

/**
 * Recursively provide parent reference for each navigation item
 *
 * @param routes
 * @param parentRoute
 * @returns {*}
 */
export const setupParents = (routes, parentRoute = null) =>
  routes.map((route) => {
    const withParent = {
      ...route,
      ...(parentRoute && { parent: parentRoute }),
    };
    return {
      ...withParent,
      ...(withParent.routes && {
        routes: setupParents(withParent.routes, withParent),
      }),
    };
  });

/**
 * Convert navigation tree into flat array
 *
 * @param routes
 * @returns {any[]}
 */
export const flattenRoutes = (routes) =>
  routes.map((route) => [route.routes ? flattenRoutes(route.routes) : [], route]).flat(Infinity);

/**
 * Combine all the above functions together
 *
 * @param routes
 * @returns {any[]}
 */
export const generateAppRoutes = (routes) => flattenRoutes(setupParents(buildPaths(routes)));

/**
 * Provides path from root to the element
 *
 * @param route
 * @returns {any[]}
 */
const pathTo = (route) => {
  if (!route.parent) {
    return [route];
  }
  return [...pathTo(route.parent), route];
};
export default pathTo;

/**
 * 그리드 컬럼 정보를 이용하여 이름 정보 확인
 * @param {react-data-grid columns} arr
 * @param {column key} key
 */
export const getNameFromArray = (arr, key) => arr.filter((it) => it.key === key)[0].name;

export const localStorageSave = (name, value) => {
  try {
    if (typeof localStorage === 'undefined') {
      alert('localStorage 를 지원합니다');
      return;
    }
    localStorage.setItem(name, value);
  } catch (e) {
    alert(e.ErrorMessage);
  }
};
export const localStorageLoad = (name) => {
  try {
    if (typeof localStorage === 'undefined') {
      alert('localStorage 를 지원합니다');
      return;
    }
    return localStorage.getItem(name);
  } catch (e) {
    return e.ErrorMessage;
  }
};
export const localStorageRemove = (name) => {
  try {
    if (typeof localStorage === 'undefined') {
      alert('localStorage 를 지원합니다');
      return;
    }
    localStorage.removeItem(name);
  } catch (e) {
    alert(e.ErrorMessage);
  }
};

/**
 * window.location.hostname;   // => aaaa.local
 * window.location.href;       // => http://aaaa.local:8088/test.jsp
 * window.location.host;       // => aaaa.local:8088
 * window.location.port;       // => 8088
 * window.location.pathname;   // => test.jsp
 * window.location.search;     // => ?gg=1
 * window.location.protocol;   // => http:
 */
export const getCurrentURL = (def = 'about') => {
  const url =
    window.location.pathname === '/' ? def : window.location.pathname.substring(1, window.location.pathname.length);
  if (url.indexOf('/')) {
    const p = url.split('/');
    return p[0];
  }
  return url;
};
export const getCurrentMenu = () => {
  if (window.location.pathname === '/') return null;
  return _.filter(JSON.parse(localStorageLoad('MENU')), { RMS: window.location.pathname });
};

export const getHeader = (p) => {
  const header = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'X-API-Key': '123',
  });
  return header;
};

export const fetchPost = (url, params, callback) => {
  const requestOptions = {
    method: 'POST',
    headers: getHeader(params),
    body: JSON.stringify(params),
    // mode:"no-cors"
  };
  fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => callback(data, null))
    .catch((error) => callback(null, error));
};

export const stringifyComponent = (Comp) => {
  try {
    return JSON.stringify(Comp);
  } catch (err) {
    return String(Comp);
  }
};

export const setCookie = (name, value, days = 7) => {
  const expires = new Date();
  expires.setDate(expires.getDate() + days);
  document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/`;
};
export const getCookie = (name) => {
  const cname = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(cname) === 0) {
      return c.substring(cname.length, c.length);
    }
  }
  return '';
};
export const toggleFullScreen = () => {
  let ret = false;
  try {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      ret = true;
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      ret = false;
    }
  } catch {}
  return ret;
};

export const paginate = (totalCount, pageblock, pageCurrent, displaypagesize = pageblock) => {
  const pageCount = Math.ceil(totalCount / pageblock);
  if (pageCount === 1) return null;
  const pages = _.range(1, pageCount + 1);
  const startIndex = (pageCurrent - 1) * pageblock;
  return {
    totalCount: totalCount,
    pageblock: pageblock,
    page: pageCurrent,
    itemss: _(pages).slice(startIndex).take(displaypagesize).value(),
  };
};
export const setPageCurrent = () => {
  const menuTabs = localStorageLoad('MENUTABS') ? JSON.parse(localStorageLoad('MENUTABS')) : [];
  let isNew = true;
  let currentMenu = null;
  if (getCurrentMenu()) currentMenu = getCurrentMenu()[0];
  console.log('currentMenu', currentMenu);
  if (currentMenu) {
    menuTabs.forEach((element) => {
      if (element.RMS === currentMenu.RMS) isNew = false;
    });
    if (isNew && currentMenu) {
      if (menuTabs.length === 10) menuTabs.shift();
      menuTabs.push(currentMenu);
      localStorageSave('MENUTABS', JSON.stringify(menuTabs));
    }
    console.log('navigation -> Page -> PageBody Current Menu :', menuTabs);
  }
};

export const getNameValue = (eventTarget, name = '') => {
  const ret = { name: '', value: '', type: '' };
  if (name && name.length > 0) {
    ret.name = name;
  } else {
    ret.name = eventTarget.name ? eventTarget.name : eventTarget.id;
  }
  ret.type = eventTarget.type;
  ret.value = eventTarget.value ? eventTarget.value : '';
  return ret;
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getYearMonth = () => {
  let dte = new Date();
  let year = dte.getFullYear();
  let month = dte.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  return `${year}-${month}`;
};
export const getDate = () => {
  let todaye = new Date();
  let year = todaye.getFullYear();
  let month = todaye.getMonth() + 1;
  let date = todaye.getDate();
  month = month < 10 ? `0${month}` : month;
  date = date < 10 ? `0${date}` : date;
  return `${year}-${month}-${date}`;
};
export const getEndDate = (strdate) => {
  let v = strdate.split('-');
  let enddate = new Date(parseInt(v[0]), parseInt(v[1]), 1 - 1);
  return enddate.getDate();
};
export const getString2Date = (strdate) => {
  let v = strdate.split('-');
  return new Date(parseInt(v[0]), parseInt(v[1]) - 1, parseInt(v[2]));
};
export const getInteger2Date = (year, month, date) => new Date(year, month, date);
export const getString2Day = (strdate) => getString2Date(strdate).getDay();
export const getInteger2Day = (year, month, date) => getInteger2Date(year, month, date).getDay();

/** 1주일 뒤의 Date Object 반환
 *  필요 시 weekNumber 지정으로 원하는 주 수로 계산 가능
 */
export const getWeekLaterDate = (weekNumber = 1) => {
  return new Date(Date.now() + 6.048e8 * weekNumber);
};
/**
 * XML Escaped character To Original character
 *
 * @param {string}
 * @returns {string}
 */
export const getXmlEscapedToOriginal = (xmlStr) => {
  if (xmlStr == null) return '';

  return xmlStr
    .replaceAll(/&apos;/g, "'")
    .replaceAll(/&quot;/g, '"')
    .replaceAll(/&gt;/g, '>')
    .replaceAll(/&lt;/g, '<')
    .replaceAll(/&amp;/g, '&');
};

/**
 * String to JSON
 *
 * @param {string}
 * @returns {string}
 */
export const jsonCheck = (str) => {
  try {
    const json = JSON.parse(str);
    //console.log(typeof json === 'object');
    return json;
  } catch (e) {
    return str;
  }
};

/** ****************************
 * 상수
 ***************************** */
export const divName = {
  conFirmDivName: 'e3-confirm-alert',
  alertDivName: 'e3-alert',
  loadingDivName: 'e3-loading',
};

export const alertType = {
  Error: 'error',
  Warning: 'warning',
  Info: 'info',
  Success: 'success',
};

export const validationType = {
  string: 'string',
  number: 'number',
  email: 'email',
  phone: 'phone',
  require: '',
};

export const e3 = {
  regex: {
    number: /^[0-9]/g,
    email: /^[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[@]{1}[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[.]{1}[A-Za-z]{1,5}$/,
    phone: /^\d{2,3}\d{3,4}\d{4}$/,
    phoneWithHyphen: /^\d{2,3}-\d{3,4}-\d{4}$/,
    timeHHMM: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
    thousandComma: /\B(?=(\d{3})+(?!\d))/g,
  },
  /** ****************************
   * Modal
   ***************************** */
  modal: {
    alert: (alertType, contents) => {
      const component = <ISOAlert alertType={alertType} contents={contents} />;

      e3.ui.createElement(divName.alertDivName, component);
    },
    confirm: (title, contents, onConfirmClick, onCloseClick) => {
      const component = (
        <ISOConfirmDialog
          title={title}
          contents={contents}
          onConfirmClick={onConfirmClick}
          onCloseClick={onCloseClick}
        />
      );

      e3.ui.createElement(divName.conFirmDivName, component);
    },
  },

  /** ****************************
   * Ui
   ***************************** */
  ui: {
    createElement: (divName, component) => {
      let divTarget = document.getElementById(divName);
      if (divTarget) {
        render(component, divTarget);
      } else {
        divTarget = document.createElement('div');
        divTarget.id = divName;
        document.body.appendChild(divTarget);
        render(component, divTarget);
      }
    },
    removeElementReconfirm: (divName) => {
      const target = document.getElementById(divName);
      if (target) {
        unmountComponentAtNode(target);
        target.parentNode.removeChild(target);
      }
    },
    showLoading: () => {
      e3.ui.createElement(divName.loadingDivName, <Loader />);
    },
    hideLoading: () => {
      e3.ui.removeElementReconfirm(divName.loadingDivName);
    },
  },
  data: {
    /**
     * string or array to jaxdatasource
     * @param datasource
     * @returns {null|*}
     */
    dataAdapter: (datasource) => {
      if (!datasource) return null;

      let convertSource = null;

      if (typeof datasource === 'string') {
        convertSource = JSON.parse(datasource);
      } else {
        convertSource = datasource;
      }
      return new jqx.dataAdapter({
        datatype: 'array',
        localdata: convertSource,
      });
    },
    /**
     * control Validation
     * @param type
     * @param args(...n)
     * @returns {boolean}
     */
    validator: (type = validationType.require, ...args) => {
      if (!args) {
        e3.modal.alert(alertType.Error, '파라미터를 전달해주세요!');
        return false;
      }

      if (args.length % 2 === 1) {
        e3.modal.alert(alertType.Error, '파라미터에 컨트롤과 메세지 형식으로 전달해주세요!');
        return false;
      }

      for (let i = 0; i < args.length; i += 2) {
        if (typeof args[i] !== 'object' || !args[i].current) {
          e3.modal.alert(alertType.Error, '파라미터 형식이 올바르지 않습니다. Component');
          return false;
        }

        if (typeof (args[i] + 1) !== 'string') {
          e3.modal.alert(alertType.Error, '파라미터 형식이 올바르지 않습니다. string');
          return false;
        }

        if (type === validationType.require) {
          if (!args[i].current.getValue()) {
            e3.modal.alert(alertType.Error, `${args[i + 1]}는(은) 필수입니다.`);
            return false;
          }
        } else if (type === validationType.number) {
          if (!args[i].current.getValue() && !e3.regex.number(args[i].current.getValue())) {
            e3.modal.alert(alertType.Error, '숫자만 입력해주세요!');
            return false;
          }
        } else if (type === validationType.email) {
          if (!args[i].current.getValue() && !e3.regex.email(args[i].current.getValue())) {
            e3.modal.alert(alertType.Error, '이메일 형식으로 입력해주세요. aaa@aaa.com');
            return false;
          }
        } else if (type === validationType.phone) {
          if (!args[i].current.getValue() && !e3.regex.phone(args[i].current.getValue())) {
            e3.modal.alert(alertType.Error, '전화번호 형식으로 입력해주세요! 010-1234-4556');
            return false;
          }
        }
      }

      return true;
    },
  },
  grid: {
    /**
     * column default options;
     */
    columnOpt: {
      text: '',
      datafield: '',
      sortable: true,
      columngroup: '',
      columntype: 'string',
      cellsrenderer: null,
      validation: null,
      cellvaluechanging: null,
      cellsformat: '',
      cellclassname: '',
      align: 'center',
      cellsalign: 'left',
      // width: 150,
      resizable: true,
      draggable: true,
      editable: false,
      classname: '',
      hidden: false,
      buttonclick: null,
      pinned: false,
      // createeditor: null,
    },
    columngroupOpt: {
      text: '',
      align: 'center',
      name: '',
      parentgroup: '',
    },
    /**
     * 칼럼 추가
     * @param options
     * @returns {{buttonclick: null, datafield: string, hidden: boolean, resizable: boolean, editable: boolean, columngroup: string, sortable: boolean, cellsrenderer: null, align: string, cellclassname: string, draggable: boolean, classname: string, width: number, cellsalign: string, text: string, cellvaluechanging: null, cellsformat: string, validation: null, columntype: string}}
     */
    setColumn: (options) => {
      let opt = options;
      let target = e3.grid.columnOpt;

      if (opt.datafield && opt.datafield === 'NO') {
        opt.width = 50;
        opt.cellsalign = 'center';
      }

      if (opt.columnType === 'currency') {
        opt.cellsrenderer = function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
          if (value !== '') {
            let answer = value.toString().replace(e3.regex.thousandComma, ',');

            return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + ';">' + answer + '</span>';
          }
        };
        opt.cellsalign = 'right';
      }

      // 셀 정렬
      if (opt.columnType === 'numberinput' && !opt.cellsalign) {
        opt.cellsalign = 'right';
      } else if (opt.columnType === 'string' && !opt.cellsalign) {
        opt.cellsalign = 'left';
      } else if (opt.columnType === 'date' && !opt.cellsalign) {
        opt.cellsalign = 'center';
      } else if (opt.columnType === 'datetime' && !opt.cellsalign) {
        opt.cellsalign = 'center';
      }

      // column format setting
      if (opt.columnType === 'date' && !opt.cellsformat) {
        opt.cellsformat = 'yyyy-MM-dd';
      } else if (opt.columnType === 'datetime' && !opt.cellsformat) {
        opt.cellsformat = 'yyyy-MM-dd HH:mm:ss';
      } else if (opt.columnType === 'numberinput' && !opt.cellsformat) {
        opt.cellsformat = 'D';
      }

      // grid combobax 자동 생성
      if (!opt.cellsformat && opt.columnType === 'combobox') {
        opt.displayfield = '';
        opt.createeditor = (row, value, editor) => {
          editor.jqxComboBox({
            source: e3.data.dataAdapter(opt.source),
            displayMembuer: 'LABEL',
            valueMember: 'VALUE',
          });
        };
      }

      if (opt.width === 0 && !opt.hidden) {
        opt.hidden = true;
      }

      return { ...target, ...opt };
    },
    setColumnGroup: (options) => {
      let opt = options;
      let target = e3.grid.columngroupOpt;

      return { ...target, ...opt };
    },
    lpad: (s, padLength, padString = '0') => {
      while (s.length <= padLength) s = padString + s;
      return s;
    },

    rpad: (s, padLength, padString = '0') => {
      while (s.length <= padLength) s += padString;
      return s;
    },
  },
  date: {
    /**
     * 형변환 날짜 > 문
     * @param date typeof date
     * @param format yyyy-mm-dd..
     * @returns {string}
     */
    dateToString: (date, format) => moment(date).format(format.toUpperCase()),
    getDate2String: (date, fmt) => {
      const d = date;
      const weekName = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
      let format = fmt === undefined ? this.props.dateFormat : fmt;
      return format.replace(/(yyyy|yy|MM|dd|E|hh|mm|ss|a\/p)/gi, ($1) => {
        switch ($1) {
          case 'yyyy':
            return d.getFullYear();
          case 'yy':
            return e3.string.lPad(d.getFullYear() % 1000, 2);
          case 'MM':
            return e3.string.lPad(d.getMonth() + 1, 2);
          case 'dd':
            return e3.string.lPad(d.getDate(), 2);
          case 'E':
            return weekName[d.getDay()];
          case 'HH':
            return e3.string.lPad(d.getHours(), 2);
          case 'hh':
            return e3.string.lPad(d.getHours() % 12 ? d.getHours() % 12 : 12, 2);
          case 'mm':
            return e3.string.lPad(d.getMinutes(), 2);
          case 'ss':
            return e3.string.lPad(d.getSeconds(), 2);
          case 'a/p':
            return d.getHours() < 12 ? '오전' : '오후';
          default:
            return $1;
        }
      });
    },
  },
  string: {
    /**
     * 좌측 문자열 채우기
     * @param val 채울값
     * @param len 채울 길이
     * @returns {string}
     */
    lPad: (val, len) => {
      const str = val.toString();
      let i = 0;
      let ret = '';
      while (i++ < len - str.length) {
        ret += '0';
      }
      return ret + str;
    },
    /**
     * 형변환 문자 > 날짜
     * @param s
     * @param format
     * @returns {Date}
     */
    stringToDate: (s, format) => moment(s, format).toDate(),
  },
  base64Decode: (data) => base64.decode(data),
  base64Encode: (data) => base64.encode(data),
  /**
   * login 사용자 정보를 가져옵니다.
   * @returns {object}
   */
  getUserInfo: () => {
    const tmp = window.sessionStorage.getItem('loginSign');
    const ret = tmp && tmp.length > 0 ? JSON.parse(base64.decode(tmp.toString())) : undefined;
    ret.USER_NAME = decodeURI(ret.USER_NAME);
    return ret;
  },
  fileAttach: async (attachGroupSeq, attachFiles, callback) => {
    try {
      if (attachGroupSeq === '' && attachFiles.length === 0) {
        // 단순 callback 수행
        if (callback) {
          callback(attachGroupSeq);
        }
      } else {
        // 기존 등록 파일목록 저장
        let uploadResult = attachFiles.filter((x) => x.seq !== undefined);

        // 신규 파일만 upload && 정보 add
        const files = attachFiles.filter((x) => x.seq === undefined);

        for (let i = 0; i < files.length; i++) {
          await uploadFile(files[i], (result) => {
            let file = {
              name: result.OriFileName,
              size: result.FileSize + '',
              type: '',
              path: result.FileName,
              uploaded: result.UploadSucceeded ? 'Y' : 'N',
              seq: '',
              rename: result.RealFileName,
            };
            uploadResult.splice(uploadResult.length, 0, file);
          });
        }
        let updateResult = await SysData.saveAttachInfo(attachGroupSeq, uploadResult);
        if (callback) {
          let attachSeq = updateResult.KeyValues[0].Value;
          callback(attachSeq, uploadResult);
        }
      }
    } catch (error) {
      e3.modal.alert(alertType.Error, error.message);
    }
  },
  sizeToText: (size) => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return `${parseFloat((size / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  },
  appandProperty: (array, properyName, defaultValue = '') => {
    array.map((item) => {
      item[properyName] = defaultValue;
    });
  },
  cloneObject: (obj) => {
    const tmep = Object.assign({}, obj);
    for (const prop of Object.getOwnPropertyNames(tmep)) {
      tmep[prop] = null;
    }

    return tmep;
  },
  treeGrid: treeGridApi,
  mergeGrid: mergeGridApi,
  dataGrid: dataGridApi,
  tableGrid: tableGridApi,
  kanban: kanbanApi,
};
