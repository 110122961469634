import React from 'react';

export default function Loading(props) {
  if (props.error) {
    console.log(props.error);
    return (
      <div className="error_img">
        <h4>ERROR</h4>
        <p>페이지를 찾을 수 없습니다.</p>
      </div>
    );
  } if (props.pastDelay) {
    return <div>Loading...</div>;
  }
  return null;
}
