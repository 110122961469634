import React from 'react';
import { useSelector } from 'react-redux';
// import useI18n from './useI18n';
import useMenuLoad from './useMenuLoad';
import usePageRole from './usePageRole';

/**
 * Redux(Reducer) Tabs 조회하여 현재 페이지의 정보를 담아두는 영역
 */
export default function usePageInfo() {
  const [pageInfo, setPageInfo] = React.useState('');
  const tabs = useSelector((state) => state.tabs);
  const { MenuLoad } = useMenuLoad();
  // const { t } = useI18n();
  const menuList = JSON.parse(MenuLoad());
  const menuIdItem = [];
  const BUTTON_ROLE_CONTROL = process.env.REACT_APP_BUTTON_ROLE_CONTROL || false; // 버튼 권한 설정 적용여부

  const findMenuItem = () => {
    try {
      const tmp = menuList.filter((item) => item.MNU_ID === menuIdItem[menuIdItem.length - 1]);
      if (tmp && tmp.lenght > 0 && tmp[0].PRNT_MNU_ID && tmp[0].PRNT_MNU_ID.length > 1) {
        menuIdItem.push(tmp[0].PRNT_MNU_ID);
        findMenuItem();
      }
    } catch (error) {
      //
    }
  };

  try {
    if (tabs && Array.isArray(tabs.tabList) && tabs.tabList.length > 0) {
      const activeTab = tabs.tabList.filter((item) => item.isActive === true)[0];
      if (pageInfo === '') { // 초기상태이면
        setPageInfo(activeTab);
      } else if (pageInfo.menuId !== activeTab.menuId) {
        setPageInfo(activeTab);
      }
      // console.log("usePageInfo --> pageInfo, activeTab ", pageInfo, activeTab);
    }

    if (pageInfo && pageInfo.menuId && pageInfo.menuId.length > 0 && !pageInfo.pageTitle) {
      menuIdItem.push(pageInfo.menuId);
      findMenuItem();
    }
  } catch (error) {
    // console.log("error", error);
  }

  /// 현재 페이지의 버튼 권한 정보를 수집하여 스토어에 저장한다.
  if (BUTTON_ROLE_CONTROL) {
    const pageRole = usePageRole(pageInfo.menuId);
  }
  React.useMemo(() => [pageInfo], []);
  return { pageInfo };
}
