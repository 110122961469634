import React from 'react';
import { useSelector } from 'react-redux';
import { List, ListItem, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';
import useMenuLoad from '../../core/hooks/useMenuLoad';

const defaultSideMenuExpansion = process.env.REACT_APP_DEFAULT_SIDE_MENU_EXPANSION === 'true';

const MenuSide = (props) => {
  const { values, setMenuState, handleClick } = props;
  const selectedTop = values.menuTop;
  const menuList = props.items;
  const [sideMenu, setSideMenu] = React.useState({}); // 3 level 메뉴가 있을 경우 사용
  // const sideMenuList = _.filter(menuList, function (item) { return (item.MNU_ID.substring(0,4) === selectedTop.substring(0,4)) > 0 && item.LVL > 2;});
  const sideMenutTitle = menuList.filter((it) => it.MENU_ID === selectedTop);
  const newMenuList = useMenuLoad()
    .Flat2Hierarchy4Args(menuList)
    .filter((item) => item.MENU_ID === selectedTop);
  const favoritMenu = useSelector((state) => state.favoritMenu).dataList;
  // const favoritMenu2 = useMenuLoad().LoadFavoritMenu();
  // if(Array.isArray(favoritMenu) && favoritMenu.length > 0) favoritMenu.map((item, index)=>(console.log(index, item)))

  const handleLocalClick = (item) => {
    const newData = { ...sideMenu, [item]: !sideMenu[item] };
    setSideMenu(newData);
  };
  const onClickSideTab = (e) => {
    setMenuState({
      ...values,
      sideTabMenuSelected: e.target.attributes.data.value, // 사이드 메뉴 상단 탭 설정
    });
  };
  const changeSideMenuAutoHidden = () => {
    setMenuState({
      ...values,
      sideMenuAutoHidden: !values.sideMenuAutoHidden, // 자동 숨김 설정 여부
      collapsed: !values.sideMenuAutoHidden, // 자동 숨김 설정에 따라 사이드 메뉴 숨김/표시
    });
  };
  // material-ui를 이용한 측면 메뉴 생성 함수 (측면 메뉴 콤포넌트)
  const sideMenuHandle = (menuArr, handleClick) => {
    if (menuArr && menuArr.children && menuArr.children.length > 0) {
      return menuArr.children.map((item, index) => {
        if (!item.children || item.children.length === 0) {
          return (
            <ListItem key={item.MENU_ID} className="side-menu-1lvl" selected={item.MENU_ID === values.menuSide} onClick={(e) => handleClick(e, item.MENU_ID)}>
              <span data-my-data={item.MENU_ID}>{item.MENU_NAME}</span>
            </ListItem>
          );
        }
        return (
          <div key={item.MENU_ID}>
            <ListItem
              className="side-menu-1lvl"
              key={item.MENU_ID}
              selected={item.MENU_ID.substring(0, item.MENU_ID.length - 3) === values.menuSide.substring(0, values.menuSide.length - 3)}
              onClick={() => handleLocalClick(item.MENU_ID)}
            >
              {item.MENU_NAME} {sideMenu[item.MENU_ID] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={defaultSideMenuExpansion ? true : sideMenu[item.MENU_ID] ? true : false} timeout="auto" unmountOnExit className="side-sub-menu-1lvl">
              {sideMenuHandle(item, handleClick)}
            </Collapse>
          </div>
        );
      });
    }
    return null;
  };

  // useEffect(() => {
  //   console.log('컴포넌트가 화면에 나타남', selectedTop);
  //   return () => {
  //     console.log('컴포넌트가 화면에서 사라짐', selectedTop);
  //  };
  // }, [selectedTop]);

  return (
    <>
      <div>
        <div className="side-header">
          <ul className="side-tab">
            <SideTabMenuComponent sidetabmenuSelected={values.sideTabMenuSelected} onClick={onClickSideTab} />
          </ul>
        </div>
        {values.sideTabMenuSelected === 'sideTabMenu10' ? (
          <div>
            {/*
              <h2 className="typography">
                즐겨찾기*/}
            {/* <span className="side-menu-auto-hidden" style={{ float: 'right' }}><Checkbox checked={values.sideMenuAutoHidden} onChange={changeSideMenuAutoHidden} color="primary" /></span> */}
            {/*
              </h2>
              */}
            <div>
              <SideMenuFavorit favoritMenu={favoritMenu} onClick={handleClick} selectedItem={values.menuSide} />
            </div>
          </div>
        ) : values.sideTabMenuSelected === 'sideTabMenu20' ? (
          <div>
            <h2 className="typography">
              {sideMenutTitle && sideMenutTitle.length > 0 ? sideMenutTitle[0].MENU_NAME : ''}
              {/* <span className="side-menu-auto-hidden" style={{ float: 'right' }}><Checkbox checked={values.sideMenuAutoHidden} onChange={changeSideMenuAutoHidden} color="primary" /></span> */}
            </h2>
            <List className={'side-menu-bar'}>{sideMenuHandle(newMenuList[0], handleClick)}</List>
          </div>
        ) : (
          <div>
            {/*
                <h2 className="typography">
                  메뉴 검색*/}
            {/* <span className="side-menu-auto-hidden" style={{ float: 'right' }}><Checkbox checked={values.sideMenuAutoHidden} onChange={changeSideMenuAutoHidden} color="primary" /></span> */}
            {/*
                </h2>*/}
            <div>
              <SideMenuSearch menuList={menuList} onClick={handleClick} />
            </div>
          </div>
        )}

        <div className="menu_hide">
          <h5>메뉴 자동감춤</h5>
          <span className="side-menu-auto-hidden" style={{ float: 'right' }}>
            <Checkbox checked={values.sideMenuAutoHidden} onChange={changeSideMenuAutoHidden} color="primary" />
          </span>
        </div>
      </div>
    </>
  );
};

MenuSide.defaultProps = {
  item: 'A0100000000',
};

/**
 * 즐겨찾기, 2단메뉴, 메뉴 검색을 선택하는 탭메뉴 콤포넌트
 */
const SideTabMenuComponent = React.memo((props) => {
  const { sidetabmenuSelected, onClick } = props;
  const sideTabMenuLinks = [
    { idx: 1, key: 'sideTabMenu10', name: '즐겨찾기' },
    { idx: 2, key: 'sideTabMenu20', name: '메뉴' },
    { idx: 3, key: 'sideTabMenu30', name: '메뉴 검색' },
  ];
  return (
    <>
      {sideTabMenuLinks.map((item) =>
        sidetabmenuSelected === item.key ? (
          <li key={item.key} data={item.key} onClick={onClick} className="active">
            {item.name}
          </li>
        ) : (
          <li key={item.key} data={item.key} onClick={onClick}>
            {item.name}
          </li>
        ),
      )}
    </>
  );
});

/**
 * 즐겨찾기 측면 메뉴 콤포넌트
 */
const SideMenuFavorit = React.memo((props) => {
  const { favoritMenu, onClick, selectedItem } = props;
  return (
    <List className="side-menu-favorit-area">
      {Array.isArray(favoritMenu) && favoritMenu.length > 0
        ? favoritMenu.map((item) => (
            <ListItem className="side-menu-1lvl" disableGutters key={item.MENU_ID} selected={item.MENU_ID === selectedItem} data-my-data={item.MENU_ID} onClick={(e) => onClick(e, item.MENU_ID)}>
              {item.MENU_NAME}
            </ListItem>
          ))
        : ''}
    </List>
  );
});

/**
 * 메뉴 검색 측면메뉴 콤포넌트
 */
const SideMenuSearch = React.memo((props) => {
  const { menuList, onClick } = props;
  const [searchMenuList, setSearchMenuList] = React.useState('[]'); // 검색 설정 값을 태에서 한정됨. 유지를 위할 경우 상위 콤포넌트로 이동 필요.

  React.useEffect(() => {
    setSearchMenuList(menuList.filter((item) => item.PROGRAM_PATH && item.PROGRAM_PATH.length > 1).sort((a, b) => (a.MENU_NAME < b.MENU_NAME ? -1 : a.MENU_NAME > b.MENU_NAME ? 1 : 0)));
  }, []);

  const onChange = (e) => {
    try {
      let newList = [];
      newList = menuList
        .filter((item) => item.PROGRAM_PATH && item.PROGRAM_PATH.length > 1)
        .filter((item) => item.MENU_NAME.match(new RegExp(e.target.value, 'i')))
        .sort((a, b) => (a.MENU_NAME < b.MENU_NAME ? -1 : a.MENU_NAME > b.MENU_NAME ? 1 : 0));
      if (newList && JSON.stringify(newList) !== '[]') {
        setSearchMenuList(newList);
      }
    } catch (e) {
      // console.log('onChange -> newList', e);
    }
  };

  return (
    <>
      <div className="side-menu-search-area" style={{ maxHeight: '800px' }}>
        <TextField required id="menulist" defaultValue="" onChange={onChange} />
        <List className="side-menu-search-bar">
          {Array.isArray(searchMenuList) && searchMenuList.length > 0
            ? searchMenuList.map((item) => (
                <ListItem className="side-menu-1lvl" disableGutters key={item.MENU_ID} data-my-data={item.MENU_ID} onClick={(e) => onClick(e, item.MENU_ID)}>
                  {item.MENU_NAME}
                </ListItem>
              ))
            : ''}
        </List>
      </div>
    </>
  );
});

export default MenuSide;
