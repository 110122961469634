import { combineReducers } from 'redux';
import loginReducer from './login';
import tabReducer from './TabReducer';
import favoritMenu from './FavoritMenuReducer';
import menuList from './MenuReducer';
import sizeStyle from './ResizeReducer';
import MessageReducer from './MessageReducer';
import PageRoleReducer from './PageRoleReducer';

const appReducer = combineReducers({
  user: loginReducer,
  tabs: tabReducer,
  message: MessageReducer,
  favoritMenu,
  menuList,
  sizeStyle,
  pageRole: PageRoleReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
