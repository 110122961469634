import React from 'react'; // useLayoutEffect
import { useDispatch, useSelector } from 'react-redux';
import ISOAxios, { DataPackage } from '../apis/ISOAxios';
import { add } from '../reducers/PageRoleReducer';

/**
 * 프로그램 페이지에 접근 가능한지 여부 및 CRUD를 확인한다.
 * @param {*} MNU_ID
 */
export default function usePageRole(MNU_ID) {
  const dispatcher = useDispatch();
  const roles = useSelector((state) => state.pageRole.roles);
  const addRole = async (item) => dispatcher(add(item));
  let pageRole = null;

  /**
     * 내부에 memoize 사용
     * 인증 설정 영역의 데이타 규격 확인 :: BIZ SYS050.SelectItem
     * 메뉴 로드 중에 권한 정보를 포함한다면 API 호출 없이 권한 확인
     * @param {*} callback
     */
  const fetchPageRole = async (callback) => {
    let params = DataPackage.create('SYS050', 'SelectItem');
    // params.KeyValues.push(DataPackage.NewKeyValue('PLT_ID', userInfo.user.PLT_ID || ''));
    params.KeyValues.push(DataPackage.NewKeyValue('MENU_ID', MNU_ID));
    try {
      const response = await ISOAxios.Execute(params);
      if (response) {
        if (typeof callback === 'function') callback(response, null);
      }
    } catch (e) {
      if (typeof callback === 'function') callback(null, e);
    }
  };

  /**
  * API Server에 해당 정보를 전송하여 확인 한다.
  */
  const loadButtonRoleData = async () => {
    await fetchPageRole((ret, err) => {
      if (err) {
        //
      } else {
        try {
          const returnData = JSON.parse(ret.JsonData); // 리턴 데이타 구조에 맞게 수정
          let role = [];
          returnData.map((e) => { role.push(e.BTN_TYPE); });
          let menuItem = { [MNU_ID]: role };
          const temp = addRole(menuItem);
          // console.log("addRole", temp, roles);
          pageRole = menuItem;
        } catch (e) {
          //
        }
      }
    });
  };

  // useLayoutEffect(() => {
  //     console.log('useLayoutEffect')
  // })

  const loadRole = () => roles.filter((item) => (Object.keys(item)[0] === MNU_ID));

  const checkRole = () => {
    let chk = loadRole();
    // console.log("checkRole", roles, chk);
    if (chk?.length > 0 && Object.keys(chk)[0] === MNU_ID) return true;
    return false;
  };

  React.useEffect(() => {
    try {
      if (MNU_ID !== 'home') {
        if (checkRole() === false) loadButtonRoleData();
        pageRole = loadRole()[0];
      }
    } catch (e) {
      // console.log(e);
    }
  }, []);

  return pageRole;
}
