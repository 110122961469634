import React from 'react';
/**
 * 레이아뭇 하단의 카피라이트 정보 출력하는 콤포넌트
 * @returns 콤포넌트
 */
export default function Copyrights() {
  return (
    <div className="footer">
      <div>Copyright ⓒ {process.env.REACT_APP_COPYRIGHTS_YEAR} {process.env.REACT_APP_COMPANY_NAME}. All rights reserved.​</div>
      <p className="version">Ver. {process.env.REACT_APP_VERSION}</p>
    </div>
  );
}
