import React from 'react';
import useMenuLoad from './useMenuLoad';
import useCookieData from './useCookieData';
import useUserInfo from './useUserInfo';
import useSessionStorage, { saveSession } from './useSessionStorage';
import useBase64 from './useBase64';
import ISOAxios, { DataPackage } from '../apis/ISOAxios';

/** *
 * LoginForm에서 사용자 로그인 처리를 담당하는 영역
 * API Server와 통신
 * 프로그램 Menu 정보 수집
 * 쿠키 데이타 처리
 * 세션 스토리지에 데이타 처리
 * F5를 누를 경우 로그인 정보 확인
 */
export default function useSignIn() {
  const { saveSessionStorage, loadSessionStorage, clearSessionStorage } = useSessionStorage('loginSign');
  const { MenuSave, MenuLoad, MenuRemove, GetFavoritMenu } = useMenuLoad();
  const { setCookie, getCookie } = useCookieData();
  const { getProfile } = useUserInfo();
  const { base64Encode } = useBase64();
  const SESSION_EXPIRATION_TERM = parseFloat(process.env.REACT_APP_SESSION_EXPIRATION_TERM) || (1000 * 60 * 60 * 24);

  const saveLoginInfo = (userId, password, isRemember) => {
    if (isRemember) {
      setCookie('userid', userId);
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        setCookie('userpw', password);
      }
    } else {
      setCookie('userid', '', -1);
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        setCookie('userpw', '', -1);
      }
    }
    setCookie('remember', isRemember);
  };

  const loadLoginInfo = () => {
    const ret = { userid: '', userpw: '' };
    ret.userid = getCookie('userid');
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      ret.userpw = getCookie('userpw');
    } else {
      ret.userpw = '';
    }
    ret.remember = getCookie('remember');
    return ret;
  };

  const MenuReload = (userId, plantId, callback) => {
    MenuSave(userId, plantId, (ret, err) => {
      callback(ret, err);
    });
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const SignIn = async (userId, password, callback, isRemember = false) => {
    let params = DataPackage.create('SGN100', 'GetLogin');
    params.KeyValues.push(DataPackage.NewKeyValue('username', userId));
    params.KeyValues.push(DataPackage.NewKeyValue('password', base64Encode(password)));
    params.Division = "USER";    
    /**
     * Token Service에서 인증 확인
     * Token이 존재하면 로그인 과정을 진행한다.
     * Token session Storage에 저장을 한다.
     * 사용자 정보 가져온 후 메뉴 및 즐겨찾기 메뉴정보를 가져온다.
     */
    try {
      const response = await ISOAxios.Login(params);
      if (response && response.Token) {
        // const jsonData = JSON.parse(response.JsonData);
        saveSession('Token', response.Token);
        let result = await getProfile(userId);
        if (result && result.JsonData && JSON.stringify(result.JsonData) !== '[]') {
          const resultData = JSON.parse(result.JsonData)[0];
          Object.keys(resultData).map((it) => resultData[it] = encodeURI(resultData[it]));
          const expiration = new Date(new Date().getTime() + SESSION_EXPIRATION_TERM);
          resultData.expiration = expiration;
          resultData.RESULT = 200;
          MenuSave(resultData.USER_ID, ''); // resultData.PLT_ID
          GetFavoritMenu(resultData.USER_ID, ''); // resultData.PLT_ID
          saveSessionStorage(resultData, true); // 로그인정보 Storage 저장
          saveLoginInfo(userId, password, isRemember);
        }
      }
      if (typeof callback === 'function') callback(response, null);
    } catch (e) {
      if (typeof callback === 'function') callback(null, e);
    }
  };

  React.useEffect(() => {
    try {
      // 이전 로그인 정보 확인 (디버깅 결과 useEffect 보다 먼저 발생함.)
      // F5 또는 새로고침을 누른 경우 호출됨
      const prevSignInfo = loadSessionStorage();
      if (prevSignInfo && prevSignInfo.USER_ID.length > 0 && prevSignInfo.RESULT === 200) {
        if (prevSignInfo && prevSignInfo.expiration) {
          // console.log("f5 refresh load data.....1 : ", );
          /// Menu Data Reload
          MenuReload(prevSignInfo.USER_ID, ''); // prevSignInfo.PLT_ID
          /// Favorit Menu Reload
          GetFavoritMenu(prevSignInfo.USER_ID, ''); // prevSignInfo.PLT_ID
          // CHECK SESSION_EXPIRATION_TERM
          if (new Date(prevSignInfo.expiration) > new Date()) {
            const expiration = new Date(new Date().getTime() + SESSION_EXPIRATION_TERM);
            prevSignInfo.expiration = expiration;
            saveSessionStorage(prevSignInfo, true); // 로그인정보 Storage 저장
          } else {
            clearSessionStorage(true);
            MenuRemove();
          }
        }
      }
    } catch (e) {
      //
    }
  }, []);

  return {
    SignIn,
    MenuReload,
    MenuLoad,
    MenuRemove,
    loadLoginInfo,
    saveSessionStorage,
    loadSessionStorage,
  };
}
