import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Check from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MessageOnlyDialog } from '../components/controls/ISODialog';
import ISOInput from '../components/controls/ISOInput';
import ISOCheckBox from '../components/controls/ISOCheckBox';
import ISOButton from '../components/controls/ISOButton';
import useSignIn from '../core/hooks/useSignIn';

/**
 * /pages/index 하위 페이지 로그인 페이지 항목을 변경할 경우
 */
export default function LoginForm() {
  const title = `${process.env.REACT_APP_COMPANY_NAME} ${process.env.REACT_APP_PROJECT_NAME} Login`; // 로그인 페이지 제목
  const isSSODefault = process.env.REACT_APP_DEFAULT_SSO_LOGIN === 'true';

  // 로그인 및 메뉴 정보 처리
  const { SignIn, loadLoginInfo } = useSignIn();
  const { userid, userpw, remember } = loadLoginInfo();
  const [values, setValues] = React.useState({
    showPassword: false,
    isLoading: false,
  });
  const userIdRef = React.useRef(ISOInput);
  const passwordRef = React.useRef(ISOInput);
  const rememberCheckRef = React.useRef(ISOCheckBox);
  const buttonRef = React.useRef(ISOButton);
  const msgRef = React.useRef();

  const handleValidation = () => {
    if (!userIdRef.current.getValue()) {
      const msg = '사용자 계정 정보를 입력하세요!';
      msgRef.current.showDialog(msg);
      return false;
    }
    if (!passwordRef.current.getValue()) {
      const msg = '비밀번호를 입력하세요!';
      msgRef.current.showDialog(msg);
      return false;
    }
    return true;
  };
  const closeDialog = () => {
    msgRef.current.hideDialog();
  };
  const onFinish = async () => {
    if (handleValidation()) {
      setValues({ ...values, isLoading: true });
      buttonRef.current.disable();
      SignIn(
        userIdRef.current.getValue(),
        passwordRef.current.getValue(),
        (ret, err) => {
          try {
            if (err) {
              if (typeof err === 'string') {
                msgRef.current.showDialog(err);
                // else console.log(err);
              } else {
                msgRef.current.showDialog(err.message);
                // else console.log(err);
              }
              setValues({ ...values, isLoading: false });
            }
            if (ret && ret.ErrorMessage && ret.ErrorMessage.length > 0) {
              msgRef.current.showDialog(ret.ErrorMessage);
            }
            buttonRef.current?.enable();
          } catch (e) {
            console.log(e);
          }
        },
        rememberCheckRef.current.getValue(),
      );
    }
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: values.showPassword });
  };
  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault(); // 화면 갱신 방지
  // };

  const onSSoLogin = () => {
    window.document.location.href = '/sso/Default.aspx';
  };

  useEffect(() => {
    // if (isSSODefault) {
    //   onSSoLogin();
    // }
  }, []);

  return (
    <>
      <CssBaseline />
      <Container maxWidth="sm" className="login_wrap">
        <Typography component="div">
          <Card className="main-login-car">
            {/*<h2 className="typography">Login</h2>*/}

            <h2 className="typography">
              <CardMedia component="img" alt={title} image="/logo_middle.png" title={title} />
              <br />
              스마트건설 관리시스템
            </h2>
            <CardContent>
              {isSSODefault ? (
                ''
              ) : (
                <>
                  <FormControl fullWidth className="login-input">
                    <ul>
                      <ISOInput
                        ref={userIdRef}
                        autoFocus={true}
                        required={true}
                        error={true}
                        aria-describedby="userid-error-text"
                        placeholder="사용자계정"
                        value={userid}
                        onKeyPress={async (e) => {
                          if (e.key === 'Enter') {
                            await onFinish();
                          }
                        }}
                      />
                      {/* {userIdRef.current.getValue() === '' ? <FormHelperText error id="userid-error-text">필수 입력 값입니다.</FormHelperText> : ''} */}
                      <ISOInput
                        ref={passwordRef}
                        type={values.showPassword ? 'text' : 'password'}
                        autoFocus={true}
                        required={true}
                        error={true}
                        aria-describedby="password-error-text"
                        placeholder="비밀번호"
                        value={userpw}
                        onKeyPress={async (e) => {
                          if (e.key === 'Enter') {
                            await onFinish();
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ul>
                    {/* {passwordRef.current.getValue() === '' ? <FormHelperText error id="password-error-text">필수 입력 값입니다.</FormHelperText> : ''} */}
                    {/* { values.isLoading ? <CircularProgress color="secondary" size={20} thickness={10} /> : <Check /> } */}
                  </FormControl>
                  <FormControl fullWidth className="login-remember">
                    <ISOCheckBox
                      ref={rememberCheckRef}
                      color="primary"
                      label="ID 저장"
                      checked={remember === 'true' || false}
                      style={{ minWidth: '100%' }}
                    />
                  </FormControl>

                  <FormControl fullWidth className="btn_wrap btn_login">
                    <ISOButton
                      ref={buttonRef}
                      fullWidth={true}
                      textLabel={'로그인'}
                      color="primary"
                      onClick={onFinish}
                    />
                  </FormControl>
                </>
              )}
              <FormControl fullWidth className="btn_wrap btn_login">
                {/* <ISOButton fullWidth={true} textLabel={'SSO Login'} color="primary" onClick={onSSoLogin} /> */}
              </FormControl>
            </CardContent>

            <div className="footer_login">
              ⓒ {process.env.REACT_APP_COPYRIGHTS_YEAR} {process.env.REACT_APP_COMPANY_NAME}
            </div>
          </Card>
        </Typography>
      </Container>
      <MessageOnlyDialog title="로그인 오류" closeDialog={closeDialog} ref={msgRef} />
    </>
  );
}
