import axios from 'axios';
import { alertType, e3 } from '../utils/utils';

const API_URL = process.env.REACT_APP_PROXY;

const axiosRequestConfig = {
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
  responseType: 'json',
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN',
};

const axiosInstance = axios.create(axiosRequestConfig);

export const DataPackage = {
  init: () => {
    let dataPackage = {};
    dataPackage.Token = '';
    dataPackage.JsonData = '';
    dataPackage.ErrorMessage = '';
    dataPackage.ProgramId = '';
    dataPackage.MethodName = '';
    dataPackage.Division = '';
    dataPackage.KeyValues = [];
    return dataPackage;
  },
  create: (programid, methodname) => {
    let dataPackage = DataPackage.init();
    dataPackage.ProgramId = programid;
    dataPackage.MethodName = methodname;
    return dataPackage;
  },
  NewKeyValue: (key, value) => {
    let keyValue = {};
    keyValue.Key = key;
    keyValue.Value = value;
    return keyValue;
  },
  GetParam4Plant: () => {
    let m = {};
    m.Key = 'PLT_ID';
    m.Value = DataPackage.GetPlantId();
    return m;
  },
  GetPlantId: () => {
    const tmp = window.sessionStorage.getItem('loginSign');
    const ret = tmp && tmp.length > 0 ? JSON.parse(e3.base64Decode(tmp.toString())) : undefined;
    return ret.PLT_ID || '';
  },
};

// 특정 환경에서는 post 호출후 response 된 값이 json으로 인식되지 않아서 해당 로직을 넣음
// 2021.09.15 이재우
const jsonCheck = (str) => {
  try {
    const json = JSON.parse(str);
    //console.log(typeof json === 'object');
    return json;
  } catch (e) {
    //console.log(false);
    return str;
  }
};

const ISOAxios = {
  SetToken: (value) => {
    window.sessionStorage.setItem('Token', value);
  },
  GetToken: () => {
    const tmp = window.sessionStorage.getItem('Token');
    const ret = tmp && tmp.length > 0 ? tmp.toString() : undefined;
    return ret;
  },
  post: (params, url) => {},
  Execute: async (params) => {
    try {
      e3.ui.showLoading();

      params.Token = ISOAxios.GetToken();
      const result = await axiosInstance.post('/execute', JSON.stringify(params));

      e3.ui.hideLoading();
      // post후 response된 값이 json으로 인식되게끔 함
      const resultData = jsonCheck(result.data);
      if (resultData.ErrorMessage) {
        e3.modal.alert(alertType.Error, resultData.ErrorMessage);
      }
      return resultData;
    } catch (err) {
      e3.ui.hideLoading();
      throw new Error(err);
    }
  },
  Login: async (params) => {
    try {
      const result = await axiosInstance.post('/login', JSON.stringify(params));

      // post후 response된 값이 json으로 인식되게끔 함
      const resultData = jsonCheck(result.data);
      return resultData;
    } catch (err) {
      throw new Error(err);
    }
  },
  GetPlantId: () => {
    const tmp = window.sessionStorage.getItem('loginSign');
    const ret = tmp && tmp.length > 0 ? JSON.parse(e3.base64Decode(tmp.toString())) : undefined;
    return ret.PLT_ID || '';
  },
};

export const GetCodes = async (codeID) => {
  let params = DataPackage.create('SYS100', 'getCodes');
  params.KeyValues.push(DataPackage.NewKeyValue('codeId', codeID));
  const result = await ISOAxios.Execute(params);
  if (result) return JSON.parse(result.JsonData);
  return null;
};

export default ISOAxios;
