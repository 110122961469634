// const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const RESET_STORE = 'RESET_STORE';
const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';
const REDUCER_LOGIN = 'REDUCER_LOGIN';

export const login = (data) => {
  // console.log("action login :: ", data);
  if (data.RESULT === 200) {
    return {
      type: LOGIN_SUCCESS,
      target: REDUCER_LOGIN,
      result: data,
    };
  }
  return {
    type: LOGIN_FAILURE,
    target: REDUCER_LOGIN,
    result: data,
  };

  // return {
  //     type: types.LOGIN,
  //     target: types.REDUCER_LOGIN,
  //     promise: { method: 'post', url: '/login', data: { id, password } }
  // };
};
export const logout = () => ({
  type: LOGOUT,
  target: REDUCER_LOGIN,
});

export const clearStore = () => ({
  type: RESET_STORE,
  target: '',
});

export const defaultState = {
  isLoggedIn: false,
  fetchingUpdate: false,
  user: {},
};

export default function loginReducer(state = defaultState, action) {
  if (action.target !== REDUCER_LOGIN) return state;
  // console.log(" loginReducer action :: ", action);
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        fetchingUpdate: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        fetchingUpdate: false,
        isLoggedIn: true,
        user: action.result,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        fetchingUpdate: false,
      };
    case LOGOUT:
      return defaultState;
    default:
      return state;
  }
}
