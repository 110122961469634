import React from 'react';
import { BrowserRouter } from 'react-router-dom'; // * BrowserRouter 불러오기
import { ThemeProvider, createMuiTheme, createTheme } from '@material-ui/core';
import { Provider } from 'react-redux';
import store from './framework/core/reducers/_store';

import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import './styles/App.css';
import './styles/style.css';
import './styles/DefaultLayout.css';
import './styles/condtrolStyle.css';
import './styles/index.css';

import 'tui-color-picker/dist/tui-color-picker.css';
import './styles/toastui-editor.css';
import './styles/toastui-editor-plugin-color-syntax.css';
import './styles/toastui-editor-plugin-table-merged-cell.css';
import 'react-datepicker/dist/react-datepicker.css';

import 'smart-webcomponents-react/source/styles/smart.default.css';

import Login from './pages/index';

const theme = createTheme({
  palette: {
    type: 'dark',
  },
});
const isUseTheme = false;

export default function App() {
  if (isUseTheme) {
    return (
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Login />
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    );
  }
  return (
    <Provider store={store}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Login />
      </BrowserRouter>
    </Provider>
  );
}
