/* 액션 타입 선언 */

import Loading from "../../components/loaderable/Loading";
import Loadable from "react-loadable";

const TABCOUNT = process.env.REACT_APP_TAB_COUNT; //FHD기준

const ADD_TAB = 'ADD_TAB';
const REMOVE_TAB = 'REMOVE_TAB';
const REMOVE_ALL = 'REMOVE_ALL';
const SELECT_TAB = 'SELECT_TAB';
const PREV_TAB = 'PREV_TAB';
const NEXT_TAB = 'NEXT_TAB';


/* 액션 생성함수 선언 */
let nextId = 1;
export const addTab = (payload) => ({
  type: ADD_TAB,
  tab: {
    id: nextId++, // 새 항목을 추가하고 nextId 값에 1을 더해줍니다.
    menuId: payload.MENU_ID,
    name: payload.MENU_NAME,
    contents: Loadable({
        loader: () => import('../../../pages' + payload.PROGRAM_PATH),
        loading: Loading
    }),
    MNU_ID: payload.MENU_ID,
    NAME_PATH: payload.NAME_PATH,
    PROGRAM_ID: payload.PROGRAM_ID,
    isActive: true
  }
});

export const removeTab = (id) => ({
  type: REMOVE_TAB,
  id: id
});

export const removeAll = () => ({
  type: REMOVE_ALL,
});

export const selectTab = (id) => ({
  type: SELECT_TAB,
  id: id,
});

export const prevTab = (id) => ({
  type: PREV_TAB,
  id: id,
})

export const nextTab = (id) => ({
  type: NEXT_TAB,
  id: id,
})

const initialState = {
  tabList : [{
      id: 0,
      menuId: 'home',
      name: 'Home',
      contents: Loadable({
          loader: () => import('../../../pages/Home'),
          loading: Loading
      }),
      isActive: true
  }]
};

const prevPage = (tabList, id) => {
    const prevPageList = tabList.filter(f=>{
        return f.id > 0 && f.id < id;
    });
    const prevPage = prevPageList[prevPageList.length - 1];
    if(prevPage == undefined){
        return 0;
    }else{
        return prevPage.id;
    }
}

const nextPage = (tabList, id) => {
    const nextPageList = tabList.filter(f=>{
        return f.id > id && id < tabList[tabList.length - 1].id;
    });
    const nextPage = nextPageList[0];
    if(nextPage == undefined){
        return id;
    }else {
        return nextPage.id;
    }
}

export default function tabReducer(state = initialState, action) {
  const tabList = state.tabList;
  let isLastPage = true;
  switch (action.type) {
    case ADD_TAB:
        return {...state,
              tabList: tabList.filter(e=>{
                      if(e.menuId == action.tab.menuId) return true;
                      else return false;
                      }).length < 1 && tabList.length < TABCOUNT ? tabList.map(e=>{
                        return {...e, isActive:false}
                      }).concat(action.tab) : tabList.map((e, index, array) => {
                                                if(e.menuId === action.tab.menuId){
                                                  isLastPage = false
                                                  return {...e, isActive: true}
                                                }
                                                else if(e.menuId === array[array.length -1].menuId && isLastPage) return {...e, isActive: true}
                                                else return {...e, isActive: false}
                                              })
              }
    case REMOVE_TAB:
        return {...state,
              tabList: tabList.filter((f, index, array)=>{
                return f.id!==action.id;
              }).map((e, index, array)=>{
                if(index === array.length - 1) return {...e, isActive: true};
                else return {...e, isActive:false};
              })
        }
    case REMOVE_ALL: 
        state = initialState;
        return state;
    case SELECT_TAB:
        return {...state,
              tabList: tabList.map(e=> {
                if (e.id === action.id) return {...e, isActive: true};
                else return {...e, isActive:false};
              })
        }
    case PREV_TAB:
        return {...state,
              tabList: tabList.map(e=>{
                          if(e.id === prevPage(tabList, action.id)) return {...e, isActive: true}
                          else return {...e, isActive: false}
                      })
        }
    case NEXT_TAB:
        return {...state,
            tabList: tabList.map(e=>{
                if(e.id === nextPage(tabList, action.id)) return {...e, isActive: true}
                else return {...e, isActive: false}
            })
        }
    default:
      return state;
  }
}