import React from 'react';
import { useDispatch } from 'react-redux';
import { removeTab, selectTab } from '../../core/reducers/TabReducer';

function TabHeader({ item, hideSideMenuClick }) {
  const dispatcher = useDispatch();
  const onClosed = (id) => dispatcher(removeTab(item.id));
  const dispacherSelect = (id) => dispatcher(selectTab(item.id));

  const onClose = (e) => {
    onClosed(e.target.value);
    hideSideMenuClick();
  };

  const labelClick = (e) => {
    dispacherSelect(e.target.value);
    hideSideMenuClick();
  };

  return (
    <>
      <button className={item.id === 0 ? 'mytab' : 'mytab'} value={item.id} onClick={labelClick}>{item.name}</button>
      {item.id === 0 ? null : <button className="mycls" type="button" value={item.id} onClick={onClose}>X</button>}
    </>
  );
}

export default TabHeader;
