import React from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import { ResizableBox } from 'react-resizable';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

function PaperComponent(props) {
  const handleId = `#${props['aria-labelledby']}`;
  return (
    <Draggable handle={handleId} cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

/**
 * Dialog Text 입력할 경우 <p> ~ </p> 에 해당하는 material-ui Component
 * @param {*} props
 */
function DialogText(props) {
  return (
    <>
      <Typography gutterBottom>
        {props.children}
      </Typography>
    </>
  );
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    cursor: 'move',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    cursor: 'pointer',
  },
});

const ISODialogTitle = withStyles(styles)((props) => {
  const { classes, children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const ISODialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const stylesAction = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  leftButton: {
    justifyContent: 'right',
  },
  centerButton: {
    justifyContent: 'center',
  },
  rightButton: {
    justifyContent: 'flex-end',
  },
});
const ISODialogActions = withStyles(stylesAction)((props) => {
  const { classes, children, buttonAlign, ...other } = props;
  const alignClass = buttonAlign === 'left' ? classes.leftButton : buttonAlign === 'center' ? classes.centerButton : classes.rightButton;
  return (
    <MuiDialogActions className={classes.root, alignClass} {...other}>
      {children}
    </MuiDialogActions>
  );
});

const ISODialog = (props) => {
  const { children, onClose, isOpen, dialogId, height, width, ...other } = props;
  return (
    <div className="dialog-customer-modal">
      <Dialog onClose={onClose} open={isOpen} className="modal-ok-close" aria-labelledby={dialogId} PaperComponent={PaperComponent} maxWidth={false} TransitionComponent={Transition}>
        <ResizableBox height={height} width={width} className="resizable">
          <div style={{ height: '100%' }}>
            {props.children}
          </div>
        </ResizableBox>
      </Dialog>
    </div>
  );
};
ISODialog.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  dialogId: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
ISODialog.defaultProps = {
  height: 600,
  width: 400,
};

/**
 * content body style class :: dialog-content-body
 * Title과 Actions의 사이즈의 변경이 있으면 스타일 시트도 변경이 필요합니다.
 * 현재 Title 64px, Actions 62px Default 값입니다.
 * @param {*} props
 * isOpen: PropTypes.bool,
 * title: PropTypes.string,
 * children: PropTypes.any,
 * buttonOkTitle: PropTypes.string,
 * buttonCloseTitle: PropTypes.string,
 * onModalOkClick: PropTypes.func,
 * onModalCloseClick: PropTypes.func.isRequired,
 * width: PropTypes.number,
 * height: PropTypes.number,
 * dialogId: PropTypes.string
 */
function ModalOkClose(props) {
  const classes = useStyles();
  const { onModalOkClick, onModalCloseClick } = props;
  return (
    <div className="dialog-okclose">
      <Dialog onClose={onModalCloseClick} open={props.isOpen} className="modal-ok-close" PaperComponent={PaperComponent} aria-labelledby={props.dialogId} maxWidth={false} TransitionComponent={Transition}>
        <ResizableBox height={props.height} width={props.width} className="resizable">
          <div style={{ height: '100%' }}>
            <ISODialogTitle onClose={onModalCloseClick} id={props.dialogId}>
              {props.title}
            </ISODialogTitle>
            <ISODialogContent className="dialog-content-body">
              <div>
                {props.children}
              </div>
            </ISODialogContent>
            <ISODialogActions buttonAlign={props.buttonAlign}>
              <Button size="small" variant="contained" className={classes.margin} color="primary" onClick={onModalOkClick}>{props.buttonOkTitle}</Button>
              <Button size="small" variant="contained" className={classes.margin} onClick={onModalCloseClick}>{props.buttonCloseTitle}</Button>
            </ISODialogActions>
          </div>
        </ResizableBox>
      </Dialog>
    </div>
  );
}
ModalOkClose.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.any,
  buttonOkTitle: PropTypes.string,
  buttonCloseTitle: PropTypes.string,
  onModalOkClick: PropTypes.func,
  onModalCloseClick: PropTypes.func.isRequired,
  buttonAlign: PropTypes.oneOf(['right', 'left', 'center']),
  width: PropTypes.number,
  height: PropTypes.number,
  dialogId: PropTypes.string,
};
ModalOkClose.defaultProps = {
  isOpen: false,
  buttonOkTitle: 'OK',
  buttonCloseTitle: 'Close',
  buttonAlign: 'right',
  width: 600,
  height: 400,
  dialogId: 'draggable-dialog-okclose',
};

/**
 * Ok(확인) 버튼만 지원하는 Modal 창입니다.
 * dialogId는 창이 움직일때 사용되는 값입니다.
 * 여러 개의 창을 사용할 경우 모두 다르게 적용이 필요합니다.
 * @param {*} props
 * isOpen: PropTypes.bool,
 * title: PropTypes.string,
 * children: PropTypes.any,
 * buttonOkTitle: PropTypes.string,
 * onModalOkClick: PropTypes.func,
 * width: PropTypes.number,
 * height: PropTypes.number,
 * dialogId: PropTypes.string,
 */
function ModalOkOnly(props) {
  const classes = useStyles();
  const { onModalOkClick } = props;
  return (
    <div className="dialog-okonly">
      <Dialog onClose={onModalOkClick} open={props.isOpen} className="modal-ok-only" PaperComponent={PaperComponent} aria-labelledby={props.dialogId} maxWidth={false} TransitionComponent={Transition}>
        <ResizableBox height={props.height} width={props.width} className="resizable">
          <div style={{ height: '100%' }}>
            <ISODialogTitle onClose={onModalOkClick} id={props.dialogId}>
              {props.title}
            </ISODialogTitle>
            <ISODialogContent className="dialog-content-body">
              <div>
                {props.children}
              </div>
            </ISODialogContent>
            <ISODialogActions buttonAlign={props.buttonAlign}>
              <Button size="small" variant="outlined" className={classes.margin} color="primary" onClick={onModalOkClick}>{props.buttonOkTitle}</Button>
            </ISODialogActions>
          </div>
        </ResizableBox>
      </Dialog>
    </div>
  );
}
ModalOkOnly.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.any,
  buttonOkTitle: PropTypes.string,
  onModalOkClick: PropTypes.func,
  buttonAlign: PropTypes.oneOf(['right', 'left', 'center']),
  width: PropTypes.number,
  height: PropTypes.number,
  dialogId: PropTypes.string,
};
ModalOkOnly.defaultProps = {
  isOpen: false,
  buttonOkTitle: 'OK',
  buttonAlign: 'right',
  width: 600,
  height: 400,
  dialogId: 'draggable-dialog-okonly',
};

const ModalOkCloseRef = React.forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = React.useState(false);
  React.useImperativeHandle(
    ref,
    () => ({
      showDialog() {
        setIsOpen(true);
      },
      hideDialog() {
        setIsOpen(false);
      },
      getStateValue() {
        return isOpen;
      },
    }),
  );
  return (
    <>
      <ModalOkClose {...props} isOpen={isOpen} />
    </>
  );
});

const ModalOkOnlyRef = React.forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = React.useState(false);
  React.useImperativeHandle(
    ref,
    () => ({
      showDialog() {
        setIsOpen(true);
      },
      hideDialog() {
        setIsOpen(false);
      },
      getStateValue() {
        return isOpen;
      },
    }),
  );
  return (
    <>
      <ModalOkOnly {...props} isOpen={isOpen} />
    </>
  );
});

const MessageOnlyDialog = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState({
    isOpen: false,
    message: '',
  });
  const closeDialog = () => {
    setOpen({
      isOpen: false,
      message: '',
    });
  };
  React.useImperativeHandle(
    ref,
    () => ({
      showDialog(msg) {
        setOpen({
          isOpen: true,
          message: msg,
        });
      },
      hideDialog() {
        setOpen({
          isOpen: false,
          message: '',
        });
      },
      getStateValue() {
        return open;
      },
    }),
  );

  return (
    <>
      <Dialog
        open={open.isOpen}
        aria-labelledby="center-button-dialog-title"
        TransitionComponent={Transition}
        className="dialog-message-content"
      >
        <ISODialogTitle id="center-button-dialog-title">{props.title}</ISODialogTitle>
        <ISODialogContent className="dialog-content-body">
          <div>
            {open.message}
          </div>
        </ISODialogContent>
        <ISODialogActions buttonAlign="center">
          <Button onClick={closeDialog} color="primary">
            확인
          </Button>
        </ISODialogActions>
      </Dialog>
    </>
  );
});

const ConfirmDialog = React.forwardRef((props, ref) => {
  const [dialog, setDialog] = React.useState({
    isOpen: false,
    message: '',
    onOkClick: null,
    onCancelClick: null,
  });
  const closeDialog = () => {
    setDialog({
      ...dialog,
      isOpen: false,
      message: '',
      onOkClick: null,
      onCancelClick: null,
    });
  };
  React.useImperativeHandle(
    ref,
    () => ({
      showDialog(msg) {
        setDialog({
          ...dialog,
          isOpen: true,
          message: msg,
        });
      },
      showFunctionDialog(msg, onOkClick, onCancelClick) {
        setDialog({
          ...dialog,
          isOpen: true,
          message: msg,
          onOkClick: onOkClick,
          onCancelClick: onCancelClick,
        });
      },
      hideDialog() {
        setDialog({
          ...dialog,
          isOpen: false,
          message: '',
          onOkClick: null,
          onCancelClick: null,
        });
      },
      getStateValue() {
        return dialog;
      },
    }),
  );

  return (
    <>
      <Dialog
        open={dialog.isOpen}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        className="dialog-confirm-content"
      >
        <ISODialogContent style={{ height: props.height ? props.height : '250px', minWidth: props.width ? props.width : '450px' }} spacing={4}>
          <Grid container spacing={3}>
            <Typography color="textPrimary" variant="h5">
              {dialog.message || '적용하시겠습니까?'}
            </Typography>
          </Grid>
        </ISODialogContent>
        <ISODialogActions>
          <Button onClick={props.onCancelClick || dialog.onCancelClick || closeDialog} color="primary">
            {props.cancelLabel || '취소'}
          </Button>
          <Button onClick={props.onOkClick || dialog.onOkClick} color="primary" autoFocus>
            {props.confirmLabel || '확인'}
          </Button>
        </ISODialogActions>
      </Dialog>
    </>
  );
});

/**
 * content body style class :: dialog-content-body
 * Title과 Actions의 사이즈의 변경이 있으면 스타일 시트도 변경이 필요합니다.
 * 현재 Title 64px, Actions 62px Default 값입니다.
 * @param {*} props
 * isOpen: PropTypes.bool,
 * title: PropTypes.string,
 * children: PropTypes.any,
 * onModalCloseClick: PropTypes.func.isRequired,
 * width: PropTypes.number,
 * height: PropTypes.number,
 * dialogId: PropTypes.string
 */
function ModalNoButton(props) {
  const { onModalClose } = props;
  return (
    <div className="dialog-nobutton">
      <Dialog onClose={onModalClose} open={props.isOpen} className="modal-ok-close" PaperComponent={PaperComponent} aria-labelledby={props.dialogId} maxWidth={false} TransitionComponent={Transition}>
        <ResizableBox height={props.height} width={props.width} className="resizable">
          <div style={{ height: '100%' }}>
            <ISODialogTitle onClose={onModalClose} id={props.dialogId}>
              {props.title}
            </ISODialogTitle>
            <ISODialogContent className="dialog-nobutton-content-body">
              <div>
                {props.children}
              </div>
            </ISODialogContent>
          </div>
        </ResizableBox>
      </Dialog>
    </div>
  );
}
ModalNoButton.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.any,
  onModalClose: PropTypes.func.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  dialogId: PropTypes.string,
};
ModalNoButton.defaultProps = {
  isOpen: false,
  width: 600,
  height: 400,
  dialogId: 'draggable-dialog-nobutton',
};

export { ModalOkClose, ModalOkOnly, ModalNoButton, ModalOkCloseRef, ModalOkOnlyRef, MessageOnlyDialog, ConfirmDialog, DialogText, PaperComponent, ISODialog, ISODialogTitle, ISODialogContent, ISODialogActions };
