import ISOAxios, { DataPackage } from '../apis/ISOAxios';
import { e3, alertType } from 'src/framework/core/utils/utils';

const createDataPackage = () => {
  let dataPackage = DataPackage.init();
  dataPackage.AssemblyName = 'E3.COM.SYS.BIZ.dll';
  dataPackage.Namespace = 'E3.COM.SYS.BIZ';
  dataPackage.ClassName = 'SYSTEM';
  return dataPackage;
};

const createSSMSDataPackage = () => {
  let dataPackage = DataPackage.init();
  dataPackage.AssemblyName = 'E3.SKMR.SYS.Biz.dll';
  dataPackage.Namespace = 'E3.SKMR.SYS.Biz';
  dataPackage.ClassName = 'SKMRSYS010';
  return dataPackage;
};

const SysData = {
  selectAttachFiles: async (attachSeq) => {
    let fileList = [];
    try {
      let param = createDataPackage();
      param.MethodName = 'SelectAttachFiles';
      param.KeyValues.push(DataPackage.NewKeyValue('ATTACH_SEQ', attachSeq));

      let response = await ISOAxios.Execute(param);

      let responseJson = JSON.parse(response.JsonData);
      let file = {};
      for (let i = 0; i < responseJson.length; i++) {
        file = {
          name: responseJson[i]['ORI_FILE_NM'],
          size: responseJson[i]['FILE_SIZE'],
          type: responseJson[i]['FILE_TYPE'],
          path: responseJson[i]['FILE_PATH'],
          uploaded: 'Y',
          seq: responseJson[i]['ATTACH_FILE_SEQ'],
          rename: responseJson[i]['SAVE_FILE_NM'],
        };

        fileList.push(file);
      }
    } catch (error) {
      e3.modal.alert(alertType.Error, error.message);
    }

    return fileList;
  },
  saveAttachInfo: async (attachGroupSeq, fileInfo) => {
    try {
      let param = createDataPackage();
      param.MethodName = 'SaveAttach';

      param.KeyValues.push(DataPackage.NewKeyValue('ATTACH_SEQ', attachGroupSeq));
      param.JsonData = JSON.stringify(fileInfo);

      return await ISOAxios.Execute(param);
    } catch (error) {
      e3.modal.alert(alertType.Error, error.message);
    }
  },
  selectMenus: async () => {
    try {
      let param = createDataPackage();
      param.MethodName = 'SelectMenus';
      let response = await ISOAxios.Execute(param);
      return JSON.parse(response.JsonData);
    } catch (error) {
      e3.modal.alert(alertType.Error, error.message);
    }
  },
};

export const SSMSData = {
  getUserInfo: async (userId) => {
    let userInfo = {
      USER_ID: '',
      USER_NAME: '',
      USER_EMAIL: '',
      COMP_CD: '',
      DEPT_ID: '',
      DEPT_NAME: '',
      MNG_COMP_CD: '',
      AUTH_LIST: [],
      MNG_DEPT_LIST: [],
    };

    try {
      let param = createSSMSDataPackage();
      param.MethodName = 'SelectUserInfo';
      param.KeyValues.push(DataPackage.NewKeyValue('USER_ID', userId));
      let response = await ISOAxios.Execute(param);

      let USER_INFO = JSON.parse(response.KeyValues[0].Value);
      userInfo.USER_ID = USER_INFO[0].USER_ID;
      userInfo.USER_NAME = USER_INFO[0].USER_NAME;
      userInfo.USER_EMAIL = USER_INFO[0].USER_EMAIL;
      userInfo.COMP_CD = USER_INFO[0].COMP_CD;
      userInfo.DEPT_ID = USER_INFO[0].DEPT_ID;
      userInfo.DEPT_NAME = USER_INFO[0].DEPT_NAME;
      userInfo.MNG_COMP_CD = USER_INFO[0].MNG_COMP_CD;

      userInfo.AUTH_LIST = JSON.parse(response.KeyValues[1].Value);
      userInfo.MNG_DEPT_LIST = JSON.parse(response.KeyValues[2].Value);

      return userInfo;
    } catch (error) {
      e3.modal.alert(alertType.Error, error.message);
    }
  },
};

export default SysData;
