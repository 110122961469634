import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TabItem from './TabItem';
import { addTab } from '../../core/reducers/TabReducer';

function TabList({ hideSideMenuClick }) {
  const tabData = useSelector((state) => state.tabs);
  return (
    <>
      <TabItem items={tabData.tabList} hideSideMenuClick={hideSideMenuClick} />
    </>
  );
}

export default TabList;
