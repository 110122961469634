import { useDispatch } from 'react-redux';
import base64 from 'base-64';
import { login, logout, clearStore } from '../reducers/login';

/**
 * 로그인 처리 및 세션 저장소에 데이타 저장 처리
 */
export default function useSessionStorage(name) {
  const dispatcher = useDispatch();
  const callLogin = (data) => dispatcher(login(data));
  const callLogout = () => dispatcher(logout());
  const callClearStore = () => dispatcher(clearStore());
  const base64Encode = (data) => base64.encode(data);
  const base64Decode = (data) => base64.decode(data);
  const clearSessionStorage = (isLogoutReducer = false) => {
    window.sessionStorage.clear();
    if (isLogoutReducer) {
      callClearStore();
      callLogout();
    }
  };
  const saveSessionStorage = (value, isLoginReducer = false) => {
    const val = typeof (value) !== 'string' ? JSON.stringify(value) : value;
    window.sessionStorage.setItem(name, base64Encode(val));
    if (isLoginReducer) callLogin(value);
  };
  const loadSessionStorage = () => {
    const tmp = window.sessionStorage.getItem(name);
    const ret = tmp && tmp.length > 0 ? JSON.parse(base64Decode(tmp.toString())) : undefined;
    return ret;
  };

  return {
    clearSessionStorage,
    saveSessionStorage,
    loadSessionStorage,
  };
}

export function saveSession(sessionName, seaaionValue) {
  const val = typeof (seaaionValue) !== 'string' ? JSON.stringify(seaaionValue) : seaaionValue;
  window.sessionStorage.setItem(sessionName, val);
}
export function loadSession(sessionName) {
  const tmp = window.sessionStorage.getItem(sessionName);
  const ret = tmp && tmp.length > 0 ? tmp.toString() : undefined;
  return ret;
}
