import base64 from 'base-64';
import useBase64 from './useBase64';

/**
 * 쿠키 데이타 처리
 * @param {*} name
 */
export default function useCookieData() {
  const { base64Encode, base64Decode, encryptAES, decryptAES } = useBase64();

  const setCookie = (name, value, days = 7) => {
    const expires = new Date();
    expires.setDate(expires.getDate() + days);
    document.cookie = `${name}=${base64Encode(value)}; expires=${expires.toUTCString()}; path=/`;
  };
  const getCookie = (name) => {
    try {
      const cname = `${name}=`;
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(cname) === 0) {
          return base64Decode(c.substring(cname.length, c.length));
        }
      }
      return '';
    } catch (e) {
      return '';
    }
  };

  const setCookieAES = (name, value, days = 7) => {
    const expires = new Date();
    expires.setDate(expires.getDate() + days);
    document.cookie = `${name}=${encryptAES(value)}; expires=${expires.toUTCString()}; path=/`;
  };
  const getCookieAES = (name) => {
    try {
      const cname = `${name}=`;
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(cname) === 0) {
          return decryptAES(c.substring(cname.length, c.length));
        }
      }
      return '';
    } catch (e) {
      return '';
    }
  };

  return {
    base64Encode,
    base64Decode,
    setCookie,
    getCookie,
    setCookieAES,
    getCookieAES,
  };
}

export function saveCookie(name, value, days = 7) {
  const expires = new Date();
  expires.setDate(expires.getDate() + days);
  document.cookie = `${name}=${base64.encode(value)}; expires=${expires.toUTCString()}; path=/`;
}
export function loadCookie(name) {
  try {
    const cname = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(cname) === 0) {
        return base64.decode(c.substring(cname.length, c.length));
      }
    }
    return '';
  } catch (e) {
    return '';
  }
}
