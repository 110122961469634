// import base64 from 'base-64';
/**
 * 로컬 스토리지 저장관련 처리
 * 로컬 스토리지는 base64Encode를 지원하지 않는 것 같다.
 * @param {*} name
 */
export default function useLocalStorage() {
  const localStorageSave = (name, value) => {
    try {
      if (typeof (localStorage) === 'undefined') { alert('localStorage 를 지원합니다'); return; }
      const data = typeof (value) !== 'string' ? JSON.stringify(value) : value;
      localStorage.setItem(name, data);
    } catch (e) {
      console.log(e.ErrorMessage);
    }
  };
  const localStorageLoad = (name) => {
    try {
      if (typeof (localStorage) === 'undefined') { alert('localStorage 를 지원합니다'); return; }
      return localStorage.getItem(name);
    } catch (e) {
      return e.ErrorMessage;
    }
  };
  const localStorageRemove = (name) => {
    try {
      if (typeof (localStorage) === 'undefined') { alert('localStorage 를 지원합니다'); return; }
      localStorage.removeItem(name);
    } catch (e) {
      alert(e.ErrorMessage);
    }
  };

  return {
    localStorageSave,
    localStorageLoad,
    localStorageRemove,
  };
}

export function StorageSave(name, value) {
  try {
    if (typeof (localStorage) === 'undefined') { alert('localStorage 를 지원합니다'); return; }
    const data = typeof (value) !== 'string' ? JSON.stringify(value) : value;
    localStorage.setItem(name, data);
  } catch (e) {
    console.log(e.ErrorMessage);
  }
}
export function StorageLoad(name) {
  try {
    if (typeof (localStorage) === 'undefined') { alert('localStorage 를 지원합니다'); return; }
    return localStorage.getItem(name);
  } catch (e) {
    return e.ErrorMessage;
  }
}
export function StorageRemove(name) {
  try {
    if (typeof (localStorage) === 'undefined') { alert('localStorage 를 지원합니다'); return; }
    localStorage.removeItem(name);
  } catch (e) {
    alert(e.ErrorMessage);
  }
}
