import React, { useEffect, useState, useRef } from 'react';
import useSessionStorage from '../../core/hooks/useSessionStorage';
import ChangePassword from '../passwordChange';
import * as utils from '../../core/utils/utils';
/**
// DB에서 가져온 Menu정보를 LocalStore에 저장하고 LocalStore에서 정보를 읽어온다.
// LVL == 1 이면 Root(/) Home
// LVL == 2 이면 Top Menu 출력하는 1차 메뉴정보이다.
*/
export default function MenuTop(props) {
  const [logOutTime, setLogoutTime] = useState(parseInt(process.env.REACT_APP_SESSION_SECOND));
  const { loadSessionStorage, clearSessionStorage } = useSessionStorage('loginSign');
  const passwordChange = useRef();
  const userInfo = loadSessionStorage();

  const { handleClick, changeLanguage, values, setMenuState } = props;
  const topMenu = props.items;
  const selectItem = values.menuTop;
  const userName = userInfo && userInfo.USER_ID ? `${decodeURI(userInfo.USER_NAME)}(${userInfo.USER_ID})` : '방문자(****)';

  const handleLogoutClick = () => {
    clearSessionStorage(true);
    // window.document.location.reload();
  };

  const onChangePassword = () => {
    passwordChange.current.showChangePassword();
  };

  const onClickFullScreen = () => {
    if (utils.toggleFullScreen() === true) {
      setMenuState({
        ...values,
        fullScreenMode: true,
      });
    } else {
      setMenuState({
        ...values,
        fullScreenMode: false,
      });
    }
  };

  const onInitLogOutTime = () => {
    setLogoutTime(parseInt(process.env.REACT_APP_SESSION_SECOND));
  };

  /**
   * 초 -> 시간 변환
   * @param time
   * @returns {string}
   */
  const timeFormat = (time) => {
    let hour = 0;
    let min = 0;
    let sec = 0;

    if (time > 0) {
      min = parseInt(time / 60);
      sec = time % 60;

      if (min > 60) {
        hour = parseInt(min / 60);
        min %= 60;
      }

      if (sec < 10) sec = `0${sec}`;
      if (min < 10) min = `0${min}`;

      return `${hour}:${min}:${sec}`;
    }
  };

  /**
   * body event 추가
   */
  // useEffect(() => {
  //   document.body.addEventListener('click', onInitLogOutTime);
  //   return () => { window.removeEventListener('click', onInitLogOutTime); };
  // }, []);

  /**
   * 로그아웃 시간 처리
   */
  // useEffect(() => {
  //   const countdown = setInterval(() => {
  //     if (logOutTime > 0) {
  //       setLogoutTime(logOutTime - 1);
  //     } else {
  //       handleLogoutClick();
  //     }
  //   }, 1000);
  //   return () => { clearInterval(countdown); };
  // }, [logOutTime]);

  return (
    <>
      <div className="menu_wrap">
        <ul className="menu" role="menu">
          { topMenu.map((it) => (selectItem === it.MENU_ID
            ? <li key={it.MENU_ID} data={it.MENU_ID} onClick={handleClick} className="menu-item menu-item-selected" role="menuitem">{it.MENU_NAME}</li>
            : <li key={it.MENU_ID} data={it.MENU_ID} onClick={handleClick} className="menu-item" role="menuitem">{it.MENU_NAME}</li>
          ))}
        </ul>
      </div>

      <div className="b_locale" style={{ visibility: 'hidden' }}>
        <select value={values.locale} className="select-locale" onChange={changeLanguage}>
          <option value="ko">한국어</option>
          <option value="en">English</option>
          <option value="de">Deutsche</option>
        </select>
      </div>

      <div className="b_logout">
        {/* <span>{timeFormat(logOutTime)}</span> */}
        <span className="ellipsis" onClick={onChangePassword}>{userName}</span><button className="logout" onClick={handleLogoutClick}>logout<em className="tooltip">logout</em></button>
        {/* full/origin 버튼 */}
        {values.fullScreenMode === false
          ? <button className="full_screen" onClick={onClickFullScreen}>Full Screen<em className="tooltip">Full Screen</em></button>
          : <button className="origin_screen" onClick={onClickFullScreen}>Origin Screen<em className="tooltip">Origin Screen</em></button>}
      </div>
      <div>
        <ChangePassword ref={passwordChange} />
      </div>
    </>
  );
}
