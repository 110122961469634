import React from 'react';
import { useDispatch } from 'react-redux';
import TabHeader from './TabHeader';
import TabPanel from './TabPanel';
import { nextTab, prevTab, removeTab, removeAll, selectTab } from '../../core/reducers/TabReducer';

function getActPage(items) {
  return items.filter((f) => f.isActive === true)[0];
}

const TabItem = ({
  items,
  hideSideMenuClick,
}) => {
  const dispathcer = useDispatch();
  const dispachPrev = (id) => dispathcer(prevTab(id));
  const dispachNext = (id) => dispathcer(nextTab(id));
  const removeAllTab = () => dispathcer(removeAll());

  const onPrevClick = (e) => {
    const activePage = getActPage(items);
    dispachPrev(activePage.id);
  };

  const onNextClick = (e) => {
    const activePage = getActPage(items);
    dispachNext(activePage.id);
  };

  const onRemoveAllClick = () => {
    removeAllTab();
  }

  return (
    <>
      <div className="tab_header">
        <div className="tab_wrap">
          <ul className="tabs">
            {items.map((item, index) => (
              <li className={item.isActive ? 'tab-link active' : 'tab-link'} key={item.id}>
                <TabHeader item={item} key={index} hideSideMenuClick={hideSideMenuClick} />
              </li>
            ))}
          </ul>
        </div>
        <div className="tab_nav">
          <button className="prev" onClick={onPrevClick}>Preview<em className="tooltip">Preview</em></button>
          <button className="next" onClick={onNextClick}>Next<em className="tooltip">Next</em></button>
          <button className="close" onClick={onRemoveAllClick}>Close All<em className="tooltip">Close All</em></button>
        </div>
      </div>
      {items.map((item) => (
        <div className={item.isActive ? 'tab-content active' : 'tab-content'} key={item.id}>
          <TabPanel item={item} key={item.id} hideSideMenuClick={hideSideMenuClick} />
        </div>
      ))}
    </>
  );
};

export default TabItem;
