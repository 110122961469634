import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import PropTypes from 'prop-types';

class ISOCheckBox extends React.PureComponent {
  constructor(props) {
    super(props);

    this.enable = this.enable.bind(this);
    this.disable = this.disable.bind(this);
    this.setValue = this.setValue.bind(this);
    this.getValue = this.getValue.bind(this);
    this.onChange = this.onChange.bind(this);

    this.state = {
      label: this.props.label,
      disabled: this.props.disabled,
      checked: this.props.checked,
      aligne: this.props.aligne,
    };
  }

  enable() {
    if (this.state.disabled) {
      this.setState({
        disabled: false,
      });
    }
  }

  disable() {
    if (!this.state.disabled) {
      this.setState({
        disabled: true,
      });
    }
  }

  setValue(source) {
    if (source) {
      this.setState({
        checked: source,
      });
    }
  }

  getValue() {
    return this.state.checked;
  }

  onChange = (e) => {
    this.setState({
      checked: e.target.checked,
    });

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  render() {
    return (
      <>
        <FormControlLabel
          {...this.props}
          disabled={this.state.disabled}
          control={(
            <Checkbox
              color="primary"
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              checked={this.state.checked}
              onChange={this.onChange}
            />
          )}
          label={this.state.label}
          labelPlacement={this.state.aligne}
        />
      </>
    );
  }
}

ISOCheckBox.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  aligne: PropTypes.string,
};

ISOCheckBox.defaultValue = {
  label: 'chechBox',
  disabled: false,
  checked: false,
  aligne: 'center',
};

export default ISOCheckBox;
