import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import LoginForm from '../framework/layout/LoginForm';
import DefaultLayout from '../framework/layout/DefaultLayout/index';

export default function Login() {
  const userInfo = useSelector((state) => state.user);

  useEffect(() => {
  });

  return (
    userInfo.isLoggedIn
      ? <DefaultLayout />
      : <LoginForm />
  );
}
